/* eslint-disable react/prop-types */
import React from 'react'

const welcomeText = `
# ChordChord New Generator has been released!

[ChordChord New Generator](https://chordchord.com/generator?utm_campaign=legacy&utm_medium=welcome_url&utm_source=legacy.chordchord.com) is a brand-new version of ChordChord. It has a lot of new features and improvements. Check it out!

---

## Good-old ChordChord still works

The old version is renamed to Legacy and is no longer supported, but it is still available for those who prefer to use it. (https://legacy.chordchord.com)


## Instructions for Legacy
1. Just press the Generate Chord Progression button and you will get the random chord sequence that is called chord progression.
2. Enjoy

## Options
-To change the key press the right button and pick between different options.
-You can also change Arpeggio and Drum Settings.

Furthermore, you can enable/disable randomization for BPM, Arpeggio, Drums and Key if you want to fix currently selected option.`

import { Container, Row, Col } from 'react-grid-system'
import ReactMarkdown from 'react-markdown'

class WelcomeText extends React.PureComponent {
  render() {
    return (
      <div className='text-block'>
        <Container>
          <Row justify='center'>
            <Col sm={12} md={10} lg={8}>
              <ReactMarkdown source={welcomeText} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}


export default WelcomeText