import React from 'react'
import { Container } from 'react-grid-system'

const BrowserIsNotSupported = () => (
  <Container>
    <h2 style={{ textAlign: 'center', paddingTop: '200px', paddingBottom: '200px' }}>Thanks for checking out legacy.chordchord.com.
         Unfortunately, your browser doesn’t support the technology that makes this site work.<br /><br />Please, use another browser, such as Firefox, Edge or Safari.
         For the best experience, view it on <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>Google Chrome</a>!</h2>
  </Container>
)

export default BrowserIsNotSupported