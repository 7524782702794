/* eslint-disable react/prop-types */
import React from 'react'
import * as LogHelper from '../helper/LogHelper'

class MobileApps extends React.PureComponent {
  render() {
    let androidLink = 'https://play.google.com/store/apps/details?id=com.chordchord.generator&referrer=utm_source%3Dchordchord%26utm_medium%3Dmobile_apps_block'
    let iosLink = 'https://apps.apple.com/app/chordchord/id1489041024?pt=chordchord&ct=mobile_apps_block'
    return (
      <div className='mobile-apps-block'>
        <div className='mobile-apps-title'>ChordChord Legacy For <span>Android &amp; iOS</span></div>
        <div className='mobile-apps-buttons'>
          <a target='_blank' rel='noopener noreferrer' href={androidLink} onClick={() => { this.sendGoogleAnalytics(androidLink) }}>
            <img src='/static/images/google_play.png' />
          </a>
          <a target='_blank' rel='noopener noreferrer' href={iosLink} onClick={() => { this.sendGoogleAnalytics(iosLink) }}>
            <img src='/static/images/app_store.png' />
          </a>
        </div>
      </div>)
  }

  sendGoogleAnalytics(value) {
    LogHelper.logCategory('Link', value)
  }
}

export default MobileApps
