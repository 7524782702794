/* eslint-disable react/prop-types */
import React from 'react'
const offsets = [0, 0.5, 1, 1.5, 2, 3, 3.5, 4, 4.5, 5, 5.5, 6]
const octaves = 2
let noteInts = []
// const noteInts = [0, 10]
class PianoView extends React.Component {
  getNotes(noteArray) {
    let noteInts = []
    noteArray.forEach(note => {
      noteInts.push(note.midi % 24)
    })
    return noteInts
  }

  render() {
    noteInts = this.props.chord ? this.getNotes(this.props.chord.notes) : []
    return (
      <div className='piano-container'>
        {
          Array.from(Array(octaves * 12).keys()).map(midi =>
            <PianoKey key={midi}
              midi={midi}
              isSelected={noteInts.includes(midi % 24)}
              isTouched={this.props.touchedNotesSet.has(midi % 24)}
              onTouchStart={this.props.onTouchStart}
              onTouchEnd={this.props.onTouchEnd}
              isSharp={([1, 3, 6, 8, 10].indexOf(midi % 12) !== -1)} />
          )
        }
      </div>
    )
  }
}

class PianoKey extends React.PureComponent {
  getKeyWidth(isSharp) {
    const value = 100 / (octaves * 7) * (isSharp ? 0.7 : 0.9)
    return value.toString() + '%'
  }

  getKeyLeft(midi, isSharp) {
    const octaveShift = Math.floor(midi / 12) * 7
    const value = 100 / (octaves * 7) * (offsets[midi % 12] + octaveShift + (isSharp ? 0.1 : 0))
    return value.toString() + '%'
  }

  render() {
    return (
      <div
        className={'piano-key'
          + (this.props.isSharp ? ' piano-accidental' : ' piano-natural')
          + (this.props.isSelected ? ' piano-selected' : '')
          + (this.props.isTouched ? ' piano-touched' : '')}
        onMouseDown={() => this.props.onTouchStart(this.props.midi)}
        onMouseUp={() => this.props.onTouchEnd(this.props.midi)}
        style={
          {
            width: this.getKeyWidth(this.props.isSharp),
            left: this.getKeyLeft(this.props.midi, this.props.isSharp),
          }
        }>
      </div>
    )
  }
}

export default PianoView